import { S3Type } from "../../../shared/constants/AppEnums";
import { convertToS3Link } from "../Utils";

export const getUserFromJwtAuth = (user) => {
  const permissions = user?.permissionRoles
    ?.filter((permissionRole) => !permissionRole.deletedAt)
    .map((permissionRole) => permissionRole?.permission?.name);
  const photoURL = convertToS3Link(
    S3Type.UID_AVATAR,
    user?.id,
    user?.s3Profile
  );
  return {
    ...user,
    permissions,
    photoURL,
  };
};
