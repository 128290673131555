export const URL_API = {
  AUTHENTICATION: {
    LOGIN: "authentication/login",
  },
  UPDATE_PROFILE: "users",
  USER_INFORMATION: "users",
  ROLE: {
    POST_ROLE: "crm-roles",
    GET_ALL_PERMISSIONS: "crm-roles/get-permissions",
    GET_ALL_ROLES: "crm-roles/get-roles",
    GET_ROLES: "crm-roles/get-roles",
    GET_ROLE: "crm-roles/id",
    PATCH_ROLE: "crm-roles",
    DELETE_ROLES: "crm-roles/delete-roles",
    GET_USERS_BY_ROLE_ID: "role/get-users-by-roleId",
  },
  USERS: {
    REFRESH_TOKEN: "authentication/refresh-token",
    POST_USER: "users/create-admin",
    GET_USERS: "users",
    GET_ALL_USERS: "users/get-users",
    GET_USER: "users",
    PATCH_USER: "users",
    DELETE_USERS: "users/delete-users",
    BLOCK_USERS: "users/block-user",
    UNBLOCK_USERS: "users/unblock-user",
    VERIFY_PHONE_USERS: "users/send-verify-phone",
    VERIFY_MAIL_USERS: "users/send-verify-mail",
    VERIFY_USER_CODE: "users/verify-user-code",
    RESET_PASSWORD: "users/reset-password",
    INTRODUCE_SALE: "users/send-introduce",
    SET_PASSWORD: "users/set-password",
    UPDATE_PASSWORD: "users/update-password",
    REGISTER_RESELLER: "users/register-reseller",
    GET_NEW_PASSWORD: "users/get-new-password",
    SEND_FORGOT_PASSWORD: "users/send-forgot-password",
    GET_RESELLER_BY_USER_ID: "users/get-infor-reseller/userId",
    PRESIGNED_URL: "users/presigned-url",
    DELETE_PROFILE_PICTURE: "users/delete-profile-picture",
    PARTNERS: "users/partners",
    APPROVE_REJECT_PARTNER: "users/approve-reject-partner",
  },
  DEVICES: {
    POST_DEVICE: "devices",
    GET_DEVICES: "devices/devices",
    GET_DEVICE: "devices",
    PATCH_UPDATE_DEVICE: "devices",
    DELETE_DEVICES: "devices/delete-devices",
  },
  PARTNER_REGISTRATION: {
    PRESIGNED_FILES: "partner-registrations/presigned-files",
    VERIFY_OTP: "partner-registrations/verify-otp",
    SEND_OTP: "partner-registrations/send-otp",
    CREATE_REGISTRATION: "partner-registrations",
    UPDATE_REGISTRATION: "partner-registrations/update-status",
    GET_REGISTRATIONS: "partner-registrations/partner-registrations",
    GET_REGISTRATION: "partner-registrations",
    DELETE_REGISTRATIONs: "partner-registrations/delete-registrations",
  },
  CONTACTS: {
    POST_CONTACT: "contacts",
    GET_CONTACTS: "contacts/contacts",
    GET_CONTACT: "contacts",
    PATCH_UPDATE_CONTACT: "contacts/update-status",
    DELETE_CONTACTS: "contacts/delete-contacts",
  },
  HISTORIES: {
    GET_HISTORIES: "users/retirement-histories",
    GET_HISTORY: "users/retirement-history",
  },
  CUSTOMERS: {
    POST_CUSTOMER: "customers",
    GET_CUSTOMERS: "customers",
    GET_CUSTOMER: "customers",
    PATCH_UPDATE_CUSTOMER: "customers",
    DELETE_CUSTOMERS: "customers/delete-customers",
  },
  S3: {
    PUT_FILE_TO_S3: "PUT_FILE_TO_S3",
  },
  PRICING: {
    GET_LIST: "rec-prices/rec-prices",
    GET_DETAIL: "rec-prices",
    CREATE: "rec-prices",
    EDIT: "rec-prices",
    DELETE: "rec-prices/delete-rec-prices"
  },
  ANALYSIS: {
    GET_SALES: "reports/sale-reports",
    GET_CLIENTS: "reports/client-reports",
    GET_CONTACTS: "reports/contact-reports",
    GET_PRICE: "reports/price-reports",
    GET_ORDER_TRACKER: "reports/order-trackers"
  }
};
