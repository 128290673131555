import React from "react";
import { GLTypography } from "@DesignSystem/react/components";
import { Menu } from "antd";
import { ItemsType } from "./AppSideBarMenu";
import AppLink from "../AppLink";
import AppPermission from "../AppPermission";

interface AppSideBarItemProps extends ItemsType {}

const AppSideBarItem: React.FC<AppSideBarItemProps> = (item) => {
  const { children, title, path, scopes, ...rest } = item;
  if (Array.isArray(children)) {
    return (
      <AppPermission role={scopes}>
        <Menu.SubMenu
          title={<GLTypography.Body>{title}</GLTypography.Body>}
          {...rest}
        >
          {children.map((sub: any, idx: number) => {
            return <AppSideBarItem key={idx.toString()} {...sub} />;
          })}
        </Menu.SubMenu>
      </AppPermission>
    );
  }
  return (
    <AppPermission role={scopes}>
      <Menu.Item {...rest}>
        <AppLink path={path}>
          <GLTypography.Body>{title}</GLTypography.Body>
        </AppLink>
      </Menu.Item>
    </AppPermission>
  );
};

export default AppSideBarItem
