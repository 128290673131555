import clsx from "clsx";
import React from "react";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import { useSidebarContext } from "../../../utility/AppContextProvider/SidebarContextProvider";
import AppScrollbar from "../../AppScrollbar";
import AppVerticalMenu from "../components/AppVerticalNav";
import MainSidebar from "../components/MainSidebar";
import "./index.style.less";
import UserInfo from "../components/UserInfo";
import AppSideBarMenu from "shared/components/AppSideBarMenu/AppSideBarMenu";

interface AppSidebarProps {
  isCollapsed: boolean;
  onToggleSidebar: () => void;
}

const AppSidebar: React.FC<AppSidebarProps> = ({
  isCollapsed,
  onToggleSidebar,
}) => {
  const { isSidebarBgImage } = useSidebarContext();

  return (
    <MainSidebar
      className={clsx("app-standard-sidebar", {
        "standard-sidebar-img-background": isSidebarBgImage,
      })}
      collapsible
      breakpoint="xl"
      collapsed={isCollapsed}
    >
      <div className="app-trigger-btn">
        {React.createElement(
          isCollapsed ? AiOutlineMenuUnfold : AiOutlineMenuFold,
          {
            className: "trigger",
            onClick: onToggleSidebar,
          }
        )}
      </div>
      <AppScrollbar
        className="app-standard-sidebar-scrollbar"
        scrollToTop={false}
      >
        <AppSideBarMenu />
        {/* <AppVerticalMenu /> */}
      </AppScrollbar>
    </MainSidebar>
  );
};

AppSidebar.displayName = "AppSidebar";
export default AppSidebar;
