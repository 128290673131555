export enum SHADOW_TYPE {
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
  none = "none",
}

export const SHADOW_TYPE_MAP = {
  [SHADOW_TYPE.sm]: "shadow-sm",
  [SHADOW_TYPE.md]: "shadow-md",
  [SHADOW_TYPE.lg]: "shadow-lg",
  [SHADOW_TYPE.xl]: "shadow-xl",
  [SHADOW_TYPE.none]: "shadow-none",
};
