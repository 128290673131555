import { Dropdown, Input, Layout, Menu } from "antd";
import React from "react";
import { FiMoreVertical } from "react-icons/fi";
import { useIntl } from "react-intl";
import AppHeaderMessages from "../../AppHeaderMessages";
import AppLanguageSwitcher from "../../AppLanguageSwitcher";
import AppNotifications from "../../AppNotifications";
import AppLogo from "../components/AppLogo";
import "./index.style.less";
import UserInfo from "../components/UserInfo";

interface AppHeaderProps {
  isCollapsed: boolean;
  onToggleSidebar: () => void;
}

const AppHeader: React.FC<AppHeaderProps> = ({
  isCollapsed,
  onToggleSidebar,
}) => {
  const { Header } = Layout;
  const { Search } = Input;
  const { messages } = useIntl();
  const menuMobile = (
    <Menu>
      <AppHeaderMessages />
      <AppNotifications />
      <AppLanguageSwitcher />
    </Menu>
  );

  return (
    <Header className="app-standard-header">
      {/* <a className="trigger" onClick={() => onToggleSidebar(!isCollapsed)}>
  			<AiOutlineMenu />
  		</a> */}
      <AppLogo />
      {/* <Search
        className="standard-header-search"
        placeholder={messages["common.searchHere"]?.toString()}
      /> */}
      <div className="app-standard-header-sectionDesktop">
        {/* <AppHeaderMessages /> */}
        <AppNotifications />
        <AppLanguageSwitcher />
        <UserInfo />
      </div>
      <div className="app-standard-header-section-mobile">
        <Dropdown overlay={menuMobile} trigger={["click"]}>
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <FiMoreVertical />
          </a>
        </Dropdown>
      </div>
    </Header>
  );
};

AppHeader.displayName = "AppHeader";
export default AppHeader;
