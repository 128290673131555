import { Avatar, Typography } from "antd";
import { convertToS3Link, testImage } from "../../../utility/Utils";
import React, { useEffect, useMemo, useState } from "react";
import { S3Type } from "shared/constants/AppEnums";
import clsx from "clsx";
import "./index.style.less";

const UserInfoItem = ({
  id,
  s3Profile,
  email,
  fullName,
  isSizeSmall = false,
  isShowContactInfo = false,
}) => {
  const [isAvatarLoadable, setIsAvatarLoadable] = useState(false);
  const avatar = useMemo(
    () => convertToS3Link(S3Type.UID_AVATAR, id, s3Profile),
    [id, s3Profile]
  );
  useEffect(() => {
    testImage(avatar).then(
      () => setIsAvatarLoadable(true),
      () => setIsAvatarLoadable(false)
    );
  }, [avatar]);

  return (
    <div className="user-info-wrapper">
      {isAvatarLoadable
        ? avatar && (
            <Avatar
              className={clsx("cr-user-info-avatar", isSizeSmall && "small")}
              src={avatar}
            />
          )
        : fullName && (
            <Avatar
              className={clsx("cr-user-info-avatar", isSizeSmall && "small")}
            >
              {fullName.charAt(0).toUpperCase()}
            </Avatar>
          )}
      <div className="user-info-identity-wrapper">
        {fullName && <span>{fullName}</span>}
      </div>
      {isShowContactInfo && (
        <div className="user-info-contact-wrapper">
          <Typography.Text style={{ color: "#1677ff" }}>
            {email}
          </Typography.Text>
        </div>
      )}
    </div>
  );
};

export default UserInfoItem;
