import { useAuthUser } from "@greenlink/utility/AuthHooks";
import React, { PropsWithChildren } from "react";
import { PermissionScopes } from "shared/types";

// TODO: Need Implement In AppContentView for RouterGenerator
interface AppPermissionProps extends PropsWithChildren {
  role?: PermissionScopes;
}

export const checkPermissionGranted = ({
  permissions = [],
  scopes = [],
}: {
  permissions: PermissionScopes;
  scopes: PermissionScopes;
}) => {
  const result = permissions.some(
    (permission) =>
      !scopes || scopes.length === 0 || scopes.includes(permission)
  );
  return result;
};

const AppPermission: React.FC<AppPermissionProps> = ({
  role = [],
  children,
}) => {
  const { user } = useAuthUser();
  const havePermission = checkPermissionGranted({
    permissions: user?.permissions,
    scopes: role,
  });
  if (!havePermission) return null;
  return children;
};

export default AppPermission;
