import { NavStyle } from "../../../shared/constants/AppEnums";
import None from "./None";
import Standard from "./Standard";

const Layouts = {
  [NavStyle.STANDARD]: Standard,
  [NavStyle.DEFAULT]: Standard,
  [NavStyle.NONE]: None,
};
export default Layouts;
