import { PERMISSIONS } from "shared/constants/AppEnums";
import {
  UserOutlined,
  DashboardOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import { PiPhoneCall, PiCheckSquare } from "react-icons/pi";

// const routesConfig = [
//   {
//     id: "dashboards",
//     messageId: "sidebar.dashboards",
//     type: "group",
//     children: [
//       {
//         id: "dashboards",
//         title: "Dashboards",
//         messageId: "sidebar.dashboards",
//         icon: <AppstoreOutlined />,
//         path: "/dashboards",
//       },
//     ],
//   },
//   {
//     id: "crm",
//     title: "CRM",
//     messageId: "sidebar.crm",
//     type: "group",
//     children: [
//       {
//         id: "users",
//         title: "Users",
//         messageId: "sidebar.crm.users",
//         icon: <UserOutlined />,
//         path: "/crm/users",
//         scopes: [
//           PERMISSIONS.CREATE_USER,
//           PERMISSIONS.EDIT_USER,
//           PERMISSIONS.DELETE_USER,
//         ],
//       },
//       {
//         id: "contact",
//         title: "Contacts",
//         messageId: "sidebar.crm.contacts",
//         icon: <ContactsOutlined />,
//         path: "/crm/contacts",
//         scopes: [
//           PERMISSIONS.VIEW_CONTACT,
//           PERMISSIONS.EDIT_CONTACT,
//           PERMISSIONS.DELETE_CONTACT,
//         ],
//       },
//       {
//         id: "partnerRegistrations",
//         title: "Partner Registrations",
//         messageId: "sidebar.crm.partnerRegistrations",
//         icon: <IoNewspaperOutline />,
//         path: "/crm/partner-registrations",
//         scopes: [
//           PERMISSIONS.VIEW_PARTNER_REGISTRATION,
//           PERMISSIONS.EDIT_PARTNER_REGISTRATION,
//           PERMISSIONS.DELETE_PARTNER_REGISTRATION,
//         ],
//       },
//     ],
//   },
//   {
//     id: "managePartners",
//     messageId: "sidebar.partner.partners",
//     type: "group",
//     children: [
//       {
//         id: "partners",
//         title: "Partners",
//         messageId: "sidebar.crm.partners",
//         icon: <PiBookOpen />,
//         path: "/crm/partners",
//         scopes: [
//           PERMISSIONS.CREATE_PARTNER,
//           PERMISSIONS.EDIT_PARTNER,
//           PERMISSIONS.DELETE_PARTNER,
//         ],
//       },
//       {
//         id: "devices",
//         title: "devices",
//         messageId: "sidebar.crm.devices",
//         icon: <CarOutlined />,
//         path: "/crm/devices",
//         scopes: [
//           PERMISSIONS.VIEW_DEVICE,
//           PERMISSIONS.CREATE_DEVICE,
//           PERMISSIONS.EDIT_DEVICE,
//           PERMISSIONS.DELETE_DEVICE,
//         ],
//       },
//       {
//         id: "customers",
//         title: "Customers",
//         messageId: "sidebar.crm.customers",
//         icon: <TeamOutlined />,
//         path: "/crm/customers",
//         scopes: [
//           PERMISSIONS.CREATE_CUSTOMER,
//           PERMISSIONS.EDIT_CUSTOMER,
//           PERMISSIONS.DELETE_CUSTOMER,
//         ],
//       },
//     ],
//   },
//   {
//     id: "system",
//     messageId: "sidebar.systems",
//     type: "group",
//     children: [
//       {
//         id: "roles",
//         title: "Roles",
//         messageId: "sidebar.systems.roles",
//         icon: <TeamOutlined />,
//         path: "/systems/roles",
//         scopes: [
//           PERMISSIONS.CREATE_ROLE,
//           PERMISSIONS.EDIT_ROLE,
//           PERMISSIONS.DELETE_ROLE,
//         ],
//       },
//       {
//         id: "settings",
//         title: "Settings",
//         messageId: "sidebar.systems.settings",
//         icon: <SettingOutlined />,
//         path: "/systems/settings",
//         scopes: [PERMISSIONS.UPDATE_SETTING],
//       },
//     ],
//   },
// ];
// export default routesConfig;

const routesConfig = [
  {
    id: "dashboards",
    title: "Dashboards",
    messageId: "sidebar.dashboards",
    icon: <DashboardOutlined />,
    path: "/dashboards",
  },
  {
    id: "management",
    title: "User Management",
    messageId: "sidebar.dashboards",
    icon: <UserOutlined />,
    children: [
      {
        id: "users",
        title: "Users",
        messageId: "sidebar.crm.users",
        path: "/crm/users",
        scopes: [
          // PERMISSIONS.CREATE_USER,
          // PERMISSIONS.EDIT_USER,
          // PERMISSIONS.DELETE_USER,
        ],
      },
      {
        id: "partner",
        title: "Partner",
        messageId: "sidebar.crm.contacts",
        path: "/crm/partner-registrations",
        scopes: [
          // PERMISSIONS.VIEW_CONTACT,
          // PERMISSIONS.EDIT_CONTACT,
          // PERMISSIONS.DELETE_CONTACT,
        ],
      },
    ],
  },
  {
    id: "contacts",
    title: "Contacts",
    messageId: "sidebar.dashboards",
    icon: <PiPhoneCall />,
    path: "/contacts",
  },
  {
    id: "requestOrder",
    title: "Request Order",
    messageId: "sidebar.dashboards",
    icon: <PiCheckSquare />,
    path: "/orders",
  },
  // {
  //   id: "priceList",
  //   title: "Price List",
  //   messageId: "sidebar.dashboards",
  //   icon: <ProfileOutlined />,
  //   path: "/prices",
  // },
];

export default routesConfig
