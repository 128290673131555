import React from "react";
import GLTypoHeading from "./GLTypoHeading";
import clsx from "clsx";

const SIZE_MAP = {
  xs: "xs",
  sm: "sm",
  md: "md",
  lg: "lg",
};

const WEIGHT_MAP = {
  regular: "gl-typography-regular",
  medium: "gl-typography-medium",
  bold: "gl-typography-bold",
} as const;

interface GLTypographyProps extends React.ComponentProps<"p"> {
  /**
   * Font Size
   * @Default md
   */
  size?: keyof typeof SIZE_MAP;
  /**
   * Font Weight
   * @Default regular
   */
  weight?: keyof typeof WEIGHT_MAP;
}

const GLTypography: React.FC<GLTypographyProps> = ({
  size = "md",
  weight = "regular",
  children,
  className,
}) => {
  const combineClassName = clsx(
    `gl-typography-${SIZE_MAP[size]}`,
    WEIGHT_MAP[weight],
    className
  );
  return <span className={combineClassName}>{children}</span>;
};

export default Object.assign(GLTypography, {
  Heading: GLTypoHeading,
  Body: GLTypography,
});
