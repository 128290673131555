import React from "react";
import { Link, LinkProps } from "react-router-dom";

interface AppLinkProps extends Omit<LinkProps, "to"> {
  path?: string;
}

const AppLink: React.FC<AppLinkProps> = ({ path, children, ...props }) => {
  if (path && path.includes("/")) {
    return (
      <Link {...props} to={path}>
        {children}
      </Link>
    );
  }
  return children;
};

export default AppLink;
