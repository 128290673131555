import defaultConfig from "@greenlink/utility/AppContextProvider/defaultConfig";
import { useSidebarContext } from "@greenlink/utility/AppContextProvider/SidebarContextProvider";
import { Menu, MenuItemProps } from "antd";
import clsx from "clsx";
import routesConfig from "pages/routeConfig";
import React from "react";
import { useLocation } from "react-router-dom";
import { MenuStyle } from "shared/constants/AppEnums";
import { PermissionScopes } from "shared/types";
import AppSideBarItem from "./AppSideBarItem";

export interface ItemsType extends Omit<MenuItemProps, "children"> {
  path?: string;
  messageId?: string;
  scopes?: PermissionScopes;
  children?: ItemsType[];
}

interface AppSideBarMenuProps {
  items?: MenuItemProps;
}

const AppSideBarMenu: React.FC<AppSideBarMenuProps> = ({ items, ...props }) => {
  const { menuStyle, sidebarColorSet } = useSidebarContext();
  const { pathname } = useLocation();
  const selectedKeys = pathname.substr(1).split("/");
  const defaultOpenKeys = selectedKeys[0];
  const [openKeys, setOpenKeys] = React.useState([defaultOpenKeys]);

  React.useEffect(() => {
    setOpenKeys([selectedKeys[selectedKeys.length - 2]]);
    // eslint-disable-next-line
  }, []);

  const onOpenChange = (keys: string[]) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };
  return (
    <Menu
      theme={sidebarColorSet.mode}
      mode="inline"
      className={clsx("app-main-sidebar-menu ", {
        "menu-rounded": menuStyle === MenuStyle.ROUNDED,
        "menu-rounded rounded-menu-reverse":
          menuStyle === MenuStyle.ROUNDED_REVERSE,
        "menu-rounded standard-menu": menuStyle === MenuStyle.STANDARD,
        "menu-rounded curved-menu": menuStyle === MenuStyle.CURVED_MENU,
        "bg-color-menu":
          sidebarColorSet.sidebarBgColor !==
          defaultConfig.sidebar.colorSet.sidebarBgColor,
      })}
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      selectedKeys={[selectedKeys[selectedKeys.length - 1]]}
    >
      {routesConfig.map((a, idx) => {
        return <AppSideBarItem {...a} key={idx.toString()} />;
      })}
    </Menu>
  );
};

export default AppSideBarMenu;
