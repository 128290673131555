import React from "react";
import "./index.style.less";
import { GLTypography } from "@DesignSystem/react/components";

interface AppLogoProps {
  name?: string;
}

const AppLogo: React.FC<AppLogoProps> = ({ name = "GREENLINK" }) => {
  return (
    <div className="app-logo">
      <img src={require("../../assets/images/app-logo.png")} alt="Logo" />
      <GLTypography.Heading className="app-logo-name" level={3}>
        {name}
      </GLTypography.Heading>
    </div>
  );
};

export default AppLogo;
