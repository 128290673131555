import { useLayoutActionsContext } from "@greenlink/utility/AppContextProvider/LayoutContextProvider";
import { message } from "antd";
import PropTypes from "prop-types";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useQueryClient } from "react-query";
import { LOCAL_STORAGE_KEY } from "shared/constants/AppConst";
import { NavStyle } from "shared/constants/AppEnums";
import { parseJwt } from "../../../utility/Utils";
import { URL_API } from "../../endpoint";
import jwtAxios from "./jwt-api";

const JWTAuthContext = createContext(null);
const JWTAuthActionsContext = createContext(null);

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

const JWTAuthAuthProvider = ({ children }) => {
  const { updateNavStyle } = useLayoutActionsContext();

  const queryClient = useQueryClient();
  const [firebaseData, setJWTAuthData] = useState({
    user: null,
    isAuthenticated: false,
    isLoading: true,
    isApproved: false,
    reason: "",
    currentStatus: "",
  });

  const getAuthUser = useCallback(async () => {
    const token = localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN);
    console.log("token", token)

    if (!token) {
      setJWTAuthData({
        user: undefined,
        isLoading: false,
        isAuthenticated: false,
        isApproved: false,
        reason: "",
        currentStatus: "",
      });
      // updateNavStyle(NavStyle.DEFAULT);
      return;
    }
    const payload = parseJwt(token);
    try {
      const data = await jwtAxios.get(
        `${URL_API.USER_INFORMATION}/${payload?.user?.id}`
      );
      if (data) {
        const { roleId, isApproved, reason, currentStatus } = data ?? {};
        jwtAxios
          .get(URL_API.ROLE.GET_ROLE, {
            params: {
              id: roleId,
            },
          })
          .then(({ permissionRoles }) => {
            if (isApproved) {
              updateNavStyle(NavStyle.DEFAULT);
            } else {
              updateNavStyle(NavStyle.NONE);
            }

            setJWTAuthData({
              user: { ...data, permissionRoles },
              isLoading: false,
              isAuthenticated: true,
              isApproved: isApproved,
              reason: reason,
              currentStatus: currentStatus,
            });
          })
          .catch(() => {
            setJWTAuthData({
              user: undefined,
              isLoading: false,
              isAuthenticated: false,
              isApproved: false,
              reason: "",
              currentStatus: "",
            });
          });
      } else {
        throw new Error("User not found");
      }
    } catch (ex) {
      setJWTAuthData({
        user: undefined,
        isLoading: false,
        isAuthenticated: false,
        isApproved: false,
        reason: "",
        currentStatus: "",
      });
    }
  }, [updateNavStyle]);
  useEffect(() => {
    getAuthUser();
  }, [getAuthUser]);

  const signInUser = async ({ email, password, isRememberMe }) => {
    try {
      const { access_token, refresh_token } = await jwtAxios.post(
        URL_API.AUTHENTICATION.LOGIN,
        {
          email,
          password,
          isRememberMe,
        }
      );
      localStorage.setItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN, access_token);
      localStorage.setItem(LOCAL_STORAGE_KEY.REFRESH_TOKEN, refresh_token);
      const payload = parseJwt(access_token);
      const data = await jwtAxios.get(
        `${URL_API.USER_INFORMATION}/${payload?.user?.id}`
      );
      const { roleId, isApproved, reason, currentStatus } = data;

      const role = await jwtAxios.get(URL_API.ROLE.GET_ROLE, {
        params: {
          id: roleId,
        },
      });
      const permissionRoles = role?.permissionRoles;

      if (isApproved) {
        updateNavStyle(NavStyle.DEFAULT);
      } else {
        updateNavStyle(NavStyle.NONE);
      }

      setJWTAuthData({
        user: { ...data, permissionRoles },
        isAuthenticated: true,
        isLoading: false,
        isApproved: isApproved,
        reason: reason,
        currentStatus: currentStatus,
      });
    } catch (error) {
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
        isApproved: false,
        reason: "",
        currentStatus: "",
      });
      message.error(error?.message);
    }
  };

  const logout = async () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_KEY.REFRESH_TOKEN);

    setJWTAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
      isApproved: false,
      reason: "",
      currentStatus: "",
    });
    queryClient.resetQueries();
  };

  return (
    <JWTAuthContext.Provider
      value={{
        ...firebaseData,
        getAuthUser,
      }}
    >
      <JWTAuthActionsContext.Provider
        value={{
          signInUser,
          logout,
        }}
      >
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;

JWTAuthAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
