import React from "react";
import { Input, InputProps } from "antd";
import clsx from "clsx";
import "./index.style.less";

const GLInput: React.FC<InputProps> = ({ className, ...props }) => {
  const combineClass = clsx("gl-input-wrap", className);
  return <Input className={combineClass} {...props} />;
};

const GLInputPass: React.FC<InputProps> = ({ className, ...props }) => {
  const combineClass = clsx("gl-input-wrap", className);
  return <Input.Password className={combineClass} {...props} />;
};

export default Object.assign(GLInput, { Password: GLInputPass });
