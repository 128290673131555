import IntlMessages from "@greenlink/utility/IntlMessages";

export const REGISTRATION_STATUS = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
};

export const REGISTRATION_STATUS_COLOR = {
  PENDING: "blue",
  APPROVED: "green",
  REJECTED: "red",
};

export const REGISTRATION_STATUS_OPTIONS = Object.keys(REGISTRATION_STATUS).map(
  (key) => ({
    value: key,
    label: (
      <IntlMessages id={`app.registrations.status.${key?.toLowerCase()}`} />
    ),
    text: (
      <IntlMessages id={`app.registrations.status.${key?.toLowerCase()}`} />
    ),
  })
);
