import jwtAxios from "@greenlink/services/auth/jwt-auth/jwt-api";
import { URL_API } from "@greenlink/services/endpoint";
import { useMutation } from "react-query";

export const postRefreshToken = (payload) =>
  jwtAxios.post(URL_API.USERS.REFRESH_TOKEN, payload);

const useRefreshToken = () => {
  return useMutation({ mutationFn: postRefreshToken });
};

export default useRefreshToken;
