import PhoneNumberInputItem from "@greenlink/core/AppFormModal/AppFormItems/PhoneNumberInputItem";
import ButtonComponent from "./ButtonComponent";

const ComponentCore = () => {
    return (
        <div>
            <ButtonComponent />
            <PhoneNumberInputItem />
        </div>
    )
}
export default ComponentCore;
