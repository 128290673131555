import React, { PropsWithChildren } from "react";
import { Button, ButtonProps } from "antd";
import type { ButtonSize } from "antd/lib/button";
import clsx from "clsx";

const BUTTON_TYPE = {
  primary: "primary",
  secondary: "secondary",
  tertiary: "tertiary",
  danger: "danger",
  icon: "icon",
  link: "link",
};

const BUTTON_SIZE = {
  small: "small",
  medium: "medium",
  large: "large",
};

type ButtonType = keyof typeof BUTTON_TYPE;

interface GLButtonProps extends ButtonProps {
  //   type: ButtonType;
}

const GLButton: React.FC<GLButtonProps> = ({
  children,
  type = "primary",
  size = "medium",
  ...props
}) => {
  const combineClass = clsx(
    "gl-btn",
    `gl-btn-${BUTTON_TYPE[type as keyof typeof BUTTON_TYPE]}`,
    `gl-btn-${BUTTON_SIZE[size as keyof typeof BUTTON_SIZE]}`
  );
  return (
    <Button {...props} className={combineClass}>
      {children}
    </Button>
  );
};

export default GLButton;
