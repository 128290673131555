import { lazy } from "react";
import { PERMISSIONS } from "shared/constants/AppEnums";
import RegistrationProgress from "./RegistrationProgress";

const Users = lazy(() => import("./InternalUsers"));
// const Customers = lazy(() => import("./Customers"));
// const Devices = lazy(() => import("./Devices"));
const Partners = lazy(() => import("../../modules/partners"));
// const PartnerRegistrations = lazy(() => import("./PartnerRegistrations"));
// const Contacts = lazy(() => import("./Contacts"));
// const RegistrationProgress = lazy(() => import("./RegistrationProgress"));

export const REGISTRATION_PROGRESS_PATH = "/crm/registration-review";

export const crmConfig = [
  // {
  //   path: "/crm/devices",
  //   element: <Devices />,
  //   scopes: [
  //     PERMISSIONS.VIEW_DEVICE,
  //     PERMISSIONS.CREATE_DEVICE,
  //     PERMISSIONS.EDIT_DEVICE,
  //     PERMISSIONS.DELETE_DEVICE,
  //   ],
  // },
  {
    path: "/crm/users",
    element: <Users />,
    scopes: [
      PERMISSIONS.CREATE_USER,
      PERMISSIONS.EDIT_USER,
      PERMISSIONS.DELETE_USER,
    ],
  },
  // {
  //   path: "/crm/customers",
  //   element: <Customers />,
  //   scopes: [
  //     PERMISSIONS.CREATE_CUSTOMER,
  //     PERMISSIONS.EDIT_CUSTOMER,
  //     PERMISSIONS.DELETE_CUSTOMER,
  //   ],
  // },
  {
    path: "/crm/partner-registrations",
    element: <Partners />,
    scopes: [
      PERMISSIONS.VIEW_PARTNER,
      PERMISSIONS.EDIT_PARTNER,
      PERMISSIONS.DELETE_PARTNER,
    ],
  },
  // {
  //   path: "/crm/contacts",
  //   element: <Contacts />,
  //   scopes: [
  //     PERMISSIONS.VIEW_CONTACT,
  //     PERMISSIONS.EDIT_CONTACT,
  //     PERMISSIONS.DELETE_CONTACT,
  //   ],
  // },
  // {
  //   path: "/crm/partner-registrations",
  //   element: <PartnerRegistrations />,
  //   scopes: [
  //     PERMISSIONS.VIEW_PARTNER_REGISTRATION,
  //     PERMISSIONS.EDIT_PARTNER_REGISTRATION,
  //     PERMISSIONS.DELETE_PARTNER_REGISTRATION,
  //   ],
  // },
  {
    path: REGISTRATION_PROGRESS_PATH,
    element: <RegistrationProgress />,
  },
];
