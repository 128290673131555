import { lazy } from "react";

const Orders = lazy(() => import("./index"));

export const ordersConfig = [
  {
    path: "/orders",
    element: <Orders />,
  },
];
