export const authRole = {
  admin: ["user", "admin"],
  user: ["user"],
};

export const RoutePermittedRole = {
  admin: "admin",
  user: "user",
};

export const ThemeStyle = {
  MODERN: "modern",
  STANDARD: "standard",
};

export const ThemeMode = {
  LIGHT: "light",
  SEMI_DARK: "semi-dark",
  DARK: "dark",
};
export const LayoutType = {
  FULL_WIDTH: "full-width",
  BOXED: "boxed",
  FRAMED: "framed",
};

export const MenuStyle = {
  DEFAULT: "default",
  STANDARD: "standard",
  ROUNDED: "rounded",
  ROUNDED_REVERSE: "rounded-reverse",
  CURVED_MENU: "curved-menu",
};

export const NavStyle = {
  DEFAULT: "default",
  MINI: "mini",
  MINI_SIDEBAR_TOGGLE: "mini-sidebar-toggle",
  STANDARD: "standard",
  HEADER_USER: "user-header",
  HEADER_USER_MINI: "user-mini-header",
  DRAWER: "drawer",
  BIT_BUCKET: "bit-bucket",
  H_DEFAULT: "h-default",
  HOR_HEADER_FIXED: "hor-header-fixed",
  HOR_DARK_LAYOUT: "hor-dark-layout",
  NONE: "none",
};
export const FooterType = {
  FIXED: "fixed",
  FLUID: "fluid",
};
export const ThemeDirection = {
  RTL: "rtl",
  LTR: "ltr",
};

export const Fonts = {
  LIGHT: "300",
  REGULAR: "400",
  MEDIUM: "500",
  BOLD: "600",
  EXTRA_BOLD: "600",
};

export const S3Type = {
  UID_AVATAR: "uid_avt",
  REVIEW: "review",
  PARTNER_FILES: "partner_files",
};

export const BannerMediaType = {
  IMAGE: "image",
  IMAGE_DETAIL: "imageDetail",
  VIDEO: "video",
};

export const AppInputType = {
  TEXT: "text",
  TEXT_AREA: "text_area",
  NUMBER: "number",
  PASSWORD: "password",
  SELECT: "select",
  UPLOAD_SINGLE_IMAGE: "uploadSingleImage",
  UPLOAD_MULTI_IMAGE: "uploadMultiImage",
  EDITOR: "editor",
  CHECKBOX: "checkbox",
  MULTISELECT: "multiselect",
  MULTISELECT_WITH_DATA: "multiselectWithData",
  SELECT_WITH_DATA: "selectWithData",
  DATE_PICKER: "datePicker",
  DATE_TIME_PICKER: "dateTimePicker",
  TIME_PICKER: "timePicker",
  RATING: "rating",
  RADIO: "radio",
  AUTO_COMPLETE: "autoComplete",
  BUTTON: "button",
  TREE_SELECT: "treeSelect",
  TREE_SELECT_DISPLAY_LIST: "treeSelectDisplayList",
  SELECT_DISPLAY_LIST: "selectDisplayList",
  PHONE_NUMBER_INPUT: "phoneNumberInput",
  FILE_UPLOAD: "fileUpload",
  DIVIDER: "divider",
};

export const SortType = {
  ASC: "ASC",
  DESC: "DESC",
};

export const PERMISSIONS = {
  VIEW_ALL_PRODUCT: "view-all-product",
  VIEW_BASE_PRODUCT: "view-base-product",
  CREATE_PRODUCT: "create-product",
  EDIT_PRODUCT: "edit-product",
  DELETE_PRODUCT: "delete-product",

  VIEW_DEVICE: "view-device",
  CREATE_DEVICE: "create-device",
  EDIT_DEVICE: "edit-device",
  DELETE_DEVICE: "delete-device",

  VIEW_KEY: "view-key",

  VIEW_CUSTOMER: "view-customer",
  CREATE_CUSTOMER: "create-customer",
  EDIT_CUSTOMER: "edit-customer",
  DELETE_CUSTOMER: "delete-customer",

  VIEW_USER: "view-user",
  CREATE_USER: "create-user",
  EDIT_USER: "edit-user",
  DELETE_USER: "delete-user",

  VIEW_PARTNER: "view-partner",
  CREATE_PARTNER: "create-partner",
  EDIT_PARTNER: "edit-partner",
  DELETE_PARTNER: "delete-partner",

  VIEW_PARTNER_REGISTRATION: "view-partner-registration",
  EDIT_PARTNER_REGISTRATION: "edit-partner-registration",
  DELETE_PARTNER_REGISTRATION: "delete-partner-registration",

  VIEW_HISTORY: "view-history",
  CREATE_HISTORY: "create-history",
  EDIT_HISTORY: "edit-history",
  DELETE_HISTORY: "delete-history",

  VIEW_CONTACT: "view-contact",
  EDIT_CONTACT: "edit-contact",
  DELETE_CONTACT: "delete-contact",
  TEST: "test",
} as const;

export const GenderEnum = {
  MALE: "Nam",
  FEMALE: "Nữ",
  OTHER: "Khác",
};

export const GENDERS = [
  {
    id: 0,
    name: GenderEnum.MALE,
    key: "MALE",
  },
  {
    id: 1,
    name: GenderEnum.FEMALE,
    key: "FEMALE",
  },
  {
    id: 2,
    name: GenderEnum.OTHER,
    key: "OTHER",
  },
];

export enum REC_UNIT {
  KWH = "kwh",
  MWH = "mwh",
}

export const UNIT_TYPES = ["PERCENT", "MONEY"];
export const COUPON_TYPES_ENUM = {
  QUANTITY: "QUANTITY",
  CREDIT: "CREDIT",
};

export const AppSearchColumn = {
  DATETIME: "DATE_TIME",
  INPUT_RANGE: "INPUT_RANGE",
  LIST_ITEM: "LIST_ITEM",
  INPUT_NUMBER: "INPUT_NUMBER",
};

export const TIME_RANGE_FILER_ENUM = {
  THIS_WEEK: "THIS_WEEK",
  LAST_WEEK: "LAST_WEEK",
  THIS_MONTH: "THIS_MONTH",
  LAST_MONTH: "LAST_MONTH",
  THIS_YEAR: "THIS_YEAR",
  LAST_YEAR: "LAST_YEAR",
  CUSTOM: "CUSTOM",
};

export const PAYMENT_METHOD_TYPE = [
  {
    name: "Authorize.Net",
    value: "Authorize.Net",
  },
  {
    name: "Cheque",
    value: "Cheque",
  },
  {
    name: "Zelle",
    value: "Zelle",
  },
  {
    name: "External Terminal",
    value: "External Terminal",
  },
  {
    name: "Other",
    value: "Other",
  },
];

export const APP_FORM_MODAL_TYPE = {
  DEFAULT: "DEFAULT",
  TAB: "TAB",
};

export const CARD_FORM_ITEM_STATUS = {
  CREATE: "CREATE",
  PREVIEW: "PREVIEW",
  VIEW: "VIEW",
  CHANGE_CARD: "CHANGE_CARD",
};

export const AppAnimates = {
  SLIDEUPIN: {
    hidden: {
      y: "100vh",
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeIn",
        delay: 0,
        when: "beforeChildren",
        duration: 0.2,
      },
    },
  },
  SLIDEUPOUT: {
    hidden: {
      y: 0,
    },
    visible: {
      opacity: 0,
      y: "100vh",
      transition: {
        ease: "easeOut",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
      },
    },
  },
  SLIDEDOWNIN: {
    hidden: {
      opacity: 0,
      y: "-100vh",
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeIn",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
      },
    },
  },
  SLIDEDOWNOUT: {
    hidden: {
      y: 0,
    },
    visible: {
      opacity: 0,
      y: "-100vh",
      transition: {
        ease: "easeOut",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
      },
    },
  },
  SLIDELEFTIN: {
    hidden: {
      x: "100vw",
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        ease: "easeIn",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
      },
    },
  },
  SLIDELEFTOUT: {
    hidden: {
      x: 0,
    },
    visible: {
      opacity: 0,
      x: "100vw",
      transition: {
        ease: "easeOut",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
      },
    },
  },
  SLIDERIGHTIN: {
    hidden: {
      x: "-100vw",
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        ease: "easeIn",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
      },
    },
  },
  SLIDERIGHTOUT: {
    hidden: {
      x: 0,
    },
    visible: {
      opacity: 0,
      x: "-100vw",
      transition: {
        ease: "easeOut",
        delay: 0,
        when: "beforeChildren",
        duration: 0.4,
      },
    },
  },
  FADEIN: {
    hidden: {
      opacity: 0,
      scale: 1,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        ease: "easeIn",
        when: "beforeChildren",
        staggerChildren: 0.15,
      },
    },
  },
  FADEOUT: {
    hidden: {
      opacity: 1,
      scale: 1,
    },
    visible: {
      opacity: 0,
      scale: 1,
      transition: {
        ease: "easeOut",
        when: "beforeChildren",
        staggerChildren: 0.15,
      },
    },
  },
};

export const USER_PROFILE_AVATAR_ACTION = {
  NONE: "NONE",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
};

export const MANAGE_PRODUCT_STOCK_FIELD_TYPE = {
  NUMBER: "NUMBER",
  IMAGE: "IMAGE",
  CHECKBOX: "CHECKBOX",
};

export const ANTD_TABLE_ON_CHANGE_ACTION = {
  PAGINATE: "paginate",
  SORT: "sort",
  FILTER: "filter",
};
export const BadgeStatus = {
  Success: "success",
  Error: "error",
  Default: "default",
  Processing: "processing",
  Warning: "warning",
};

export const RoleIdEnum = {
  PARTNER: "1",
  ADMIN: "2",
};
