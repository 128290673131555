import { createRoot } from "react-dom/client";
import "./@greenlink/services/index";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./shared/styles/richbeauty.less";
import "./shared/styles/vendors/index.css";

const container = document.getElementById("root");
if (container) {
  const root = createRoot(container);
  root.render(<App />);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals((a) => {
  console.log("Web Vitals Report", a);
});
