// @ts-nocheck
import React from "react";

const Dashboard = React.lazy(() => import("./Dashboard"));

export const dashboardConfig = [
  {
    path: "/dashboards",
    element: <Dashboard />,
  },
];
