import { GLButton } from "@DesignSystem/react/components";
import React from "react";

const ButtonComponent = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        backgroundColor: "white",
        margin: -32,
        padding: 32,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: 12,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: 12,
          }}
        >
          <GLButton size="small">Text</GLButton>
          <GLButton size="small" disabled>
            Text
          </GLButton>
        </div>
        <GLButton size={"medium" as any}>dqdqdqwdw</GLButton>
        <GLButton size={"large" as any}>dqdqdqwdw</GLButton>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: 12,
        }}
      >
        <div>
          <GLButton size="small" type={"secondary" as any}>
            Text
          </GLButton>
        </div>
        <GLButton size={"medium" as any} type={"secondary" as any} disabled>
          Text
        </GLButton>
        <GLButton size={"large" as any} type={"secondary" as any}>
          dqdqdqwdw
        </GLButton>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: 12,
        }}
      >
        <GLButton size="small" type={"tertiary" as any}>
          Tertiary
        </GLButton>
        <GLButton size="small" type={"tertiary" as any} disabled>
          Tertiary
        </GLButton>
        <GLButton size={"medium" as any}>dqdqdqwdw</GLButton>
        <GLButton size={"large" as any}>dqdqdqwdw</GLButton>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: 12,
        }}
      >
        <div>
          <GLButton size="small">dqdqdqwdw</GLButton>
        </div>
        <GLButton size={"medium" as any}>dqdqdqwdw</GLButton>
        <GLButton size={"large" as any}>dqdqdqwdw</GLButton>
      </div>
    </div>
  );
};

export default ButtonComponent;
