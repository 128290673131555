import { lazy } from "react";

const Contacts = lazy(() => import("./index"));

export const contactConfig = [
  {
    path: "/contacts",
    element: <Contacts />,
  },
];
