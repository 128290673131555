import {
  CloseCircleOutlined,
  LoadingOutlined,
  SmileOutlined,
  SolutionOutlined,
  UserOutlined,
} from "@ant-design/icons";
import AppLogo from "@greenlink/core/AppLayout/components/AppLogo";
import AppPageMetadata from "@greenlink/core/AppPageMetadata";
import AppsContainer from "@greenlink/core/AppsContainer";
import AppsContent from "@greenlink/core/AppsContainer/AppsContent";
import AppsHeader from "@greenlink/core/AppsContainer/AppsHeader";
import { Button, Card, Steps } from "antd";
import { useIntl } from "react-intl";
import "./index.styles.less";
import { useAuthMethod, useAuthUser } from "@greenlink/utility/AuthHooks";
import { useMemo } from "react";
import { REGISTRATION_STATUS } from "../PartnerRegistrations/constants";

const STATE_ICON = {
  PROCESS: "process",
  FINISH: "finish",
};

const RegistrationProgress = () => {
  const { messages } = useIntl();
  const { reason, currentStatus } = useAuthUser();
  const { logout } = useAuthMethod();
  const { titleStep, statusStep, iconStep, titleContent, content } =
    useMemo(() => {
      switch (currentStatus) {
        case REGISTRATION_STATUS.APPROVED:
          return {
            titleStep: messages["app.status.approved"]?.toString(),
            statusStep: STATE_ICON.FINISH,
            iconStep: <SmileOutlined />,
            titleContent:
              messages["app.registrations.successScreen.title"]?.toString(),
            content: messages["app.reviewReseller.noti.success"]?.toString(),
          };
        case REGISTRATION_STATUS.REJECTED:
          return {
            titleStep: messages["app.status.rejected"]?.toString(),
            statusStep: STATE_ICON.FINISH,
            iconStep: <CloseCircleOutlined style={{ color: "#ff4d4f" }} />,
            titleContent:
              messages["app.reviewReseller.noti.reject"]?.toString(),
            content: `Thank you for considering partnership with us. We appreciate
            your interest in collaborating. However, after careful
            consideration, we regret to inform you that we are unable to
            proceed with your partner registration request at this time.
            The reason is ${reason}.`,
          };

        case REGISTRATION_STATUS.PENDING:
          return {
            titleStep: messages["app.reviewReseller.result"]?.toString(),
            statusStep: STATE_ICON.PROCESS,
            iconStep: <LoadingOutlined />,
            titleContent:
              messages["app.registrations.successScreen.title"]?.toString(),
            content: messages["app.reviewReseller.noti.waiting"]?.toString(),
          };
      }
    }, [currentStatus, messages, reason]);

  return (
    <>
      <AppPageMetadata title={messages["sidebar.crm.reviewing"]} />
      <div className="reseller-process__container" key={"wrap"}>
        <Card className="reseller-process__card">
          <AppsContainer
            title={messages["sidebar.crm.reviewing"]?.toString()}
            headerRightElement={
              <Button type="primary" onClick={() => logout()}>
                {messages["common.logout"]?.toString()}
              </Button>
            }
            type="bottom"
            fullView
          >
            <AppsHeader>
              <div className="page-header">
                <Steps
                  items={[
                    {
                      title:
                        messages["app.reviewReseller.register"]?.toString(),
                      status: "finish",
                      icon: <UserOutlined />,
                    },
                    {
                      title:
                        messages["app.reviewReseller.processing"]?.toString(),
                      status: "finish",
                      icon: <SolutionOutlined />,
                    },
                    {
                      title: titleStep,
                      status: statusStep,
                      icon: iconStep,
                    },
                  ]}
                />
              </div>
            </AppsHeader>
            <AppsContent
              style={{
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              <div className="success-screen__container">
                <AppLogo />

                <p className="success-screen__header">{titleContent}</p>
                <p className="success-screen__content">{content}</p>
              </div>
            </AppsContent>
          </AppsContainer>
        </Card>
      </div>
    </>
  );
};

export default RegistrationProgress;
