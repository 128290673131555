import React from "react";
import clsx from "clsx";

const LEVEL_MAP = {
  1: "h1",
  2: "h2",
  3: "h3",
  4: "h4",
  5: "h5",
  6: "h6",
} as const;

const WEIGHT_MAP = {
  bold: "bold",
  semi: "semi",
} as const;

interface GLTypoHeadingProps extends React.ComponentProps<"h1"> {
  /**
   * Level of the heading. Determines the HTML tag used.
   * @Default 1
   */
  level?: keyof typeof LEVEL_MAP;
  weight?: keyof typeof WEIGHT_MAP;
}

const GLTypoHeading: React.FC<GLTypoHeadingProps> = ({
  level = 1,
  weight = "bold",
  children,
  className,
}) => {
  const Heading = LEVEL_MAP[level];
  const combineClassName = clsx(`gl-heading-${WEIGHT_MAP[weight]}`, className);
  return <Heading className={combineClassName}>{children}</Heading>;
};

export default GLTypoHeading;
