import axios from "axios";
import { postRefreshToken } from "hooks/apis/users/useRefreshToken";
import { LOCAL_STORAGE_KEY } from "shared/constants/AppConst";

const jwtAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL, //YOUR_API_URL HERE
  headers: {
    "Content-Type": "application/json",
  },
});

jwtAxios.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN);
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

jwtAxios.interceptors.response.use(
  (res) => res?.data,
  async (error) => {
    if (error.response && error.config) {
      const originalConfig = error.config;
      if (error.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const refreshToken = localStorage.getItem(
            LOCAL_STORAGE_KEY.REFRESH_TOKEN
          );
          if (refreshToken) {
            const { access_token, refresh_token } = await postRefreshToken({
              refreshToken,
            });

            localStorage.setItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN, access_token);
            localStorage.setItem(
              LOCAL_STORAGE_KEY.REFRESH_TOKEN,
              refresh_token
            );

            return jwtAxios(originalConfig);
          }
        } catch (e) {
          const statusCode = e.statusCode;
          if (statusCode === 400) {
            localStorage.removeItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN);
            localStorage.removeItem(LOCAL_STORAGE_KEY.REFRESH_TOKEN);
            window.location.reload();
          }
          console.log(e);
          return Promise.reject(error.response?.data);
        }
      }
    }
    return Promise.reject(error.response?.data);
  }
);

export default jwtAxios;
