import IntlMessages from "@greenlink/utility/IntlMessages";
import UserActionInfoItem from "@greenlink/core/AppViewItems/UserActionInfoItem";
import { TIME_RANGE_FILER_ENUM } from "./AppEnums";
import moment from "moment";

export const initialUrl = "/dashboards"; // this url will open after login
export const PageDefault = 1;
export const TakeDefault = 10;

export const TABLE_ACTION_COLUMN_KEY = "action";

export const UserActionColumns = [
  {
    title: <IntlMessages id="common.createBy" />,
    key: "createBy",
    width: 220,
    align: "center",
    render: (data) => {
      const { createdAt, createdUser } = data ?? {};
      const { id, username, s3Profile, email, fullName } = createdUser ?? {};

      return (
        <UserActionInfoItem
          id={id}
          s3Profile={s3Profile}
          time={createdAt}
          displayName={fullName || email || username}
        />
      );
    },
  },
  {
    title: <IntlMessages id="common.updateBy" />,
    key: "updateBy",
    align: "center",
    width: 220,
    render: (data) => {
      const { updatedAt, updatedUser } = data;
      if (!updatedUser) return null;
      const { id, username, s3Profile, email, fullName } = updatedUser;
      return (
        <UserActionInfoItem
          id={id}
          s3Profile={s3Profile}
          time={updatedAt}
          displayName={fullName || email || username}
        />
      );
    },
  },
];

export const TIME_RANGE_FILTER = new Map([
  [
    TIME_RANGE_FILER_ENUM.THIS_WEEK,
    {
      label: "This week",
      value: {
        startDate: moment().startOf("week"),
        endDate: moment(),
      },
    },
  ],
  [
    TIME_RANGE_FILER_ENUM.LAST_WEEK,
    {
      label: "Last week",
      value: {
        startDate: moment().subtract(1, "week").startOf("week"),
        endDate: moment().subtract(1, "week").endOf("week"),
      },
    },
  ],
  [
    TIME_RANGE_FILER_ENUM.THIS_MONTH,
    {
      label: "This month",
      value: {
        startDate: moment().startOf("month"),
        endDate: moment(),
      },
    },
  ],
  [
    TIME_RANGE_FILER_ENUM.LAST_MONTH,
    {
      label: "Last month",
      value: {
        startDate: moment().subtract(1, "month").startOf("month"),
        endDate: moment().subtract(1, "month").endOf("month"),
      },
    },
  ],
  [
    TIME_RANGE_FILER_ENUM.THIS_YEAR,
    {
      label: "This year",
      value: {
        startDate: moment().startOf("year"),
        endDate: moment(),
      },
    },
  ],
  [
    TIME_RANGE_FILER_ENUM.LAST_YEAR,
    {
      label: "Last year",
      value: {
        startDate: moment().subtract(1, "year").startOf("year"),
        endDate: moment().subtract(1, "year").endOf("year"),
      },
    },
  ],
  [
    TIME_RANGE_FILER_ENUM.CUSTOM,
    {
      label: "Custom time range",
    },
  ],
]);

export const MONTHS_IN_YEAR = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const ROLE_MEMBER_NAME = "Member";

export const PASSWORD_RULES =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,}$/;

export const USERNAME_RULE = /^[a-zA-Z0-9]{8,}$/;

export const COMMON_FORM_ITEM_RULE = {
  REQUIRED: {
    required: true,
    message: <IntlMessages id="common.required" />,
  },
  PASSWORD: {
    pattern: PASSWORD_RULES,
    message: <IntlMessages id="userProfile.incorrectFormatPassword" />,
  },
  EMAIL: {
    type: "email",
    message: <IntlMessages id="common.invalidEmail" />,
  },
  RE_ENTER_PASSWORD:
    ({ messageIntl, passwordFieldName }) =>
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue(passwordFieldName) === value) {
          return Promise.resolve();
        }
        return Promise.reject(
          new Error(messageIntl["userProfile.passwordNotMatch"])
        );
      },
    }),
  USERNAME: {
    pattern: USERNAME_RULE,
    message:
      "Username must contain 8 or more characters, have not special and space character",
  },
  PHONE: (messageIntl) => ({
    validator(_, value) {
      if (
        !value ||
        (value?.length >= 10 &&
          value?.length <= 11 &&
          !Number.isNaN(Number(value)))
      ) {
        return Promise.resolve();
      }

      return Promise.reject(new Error(messageIntl["common.invalidPhone"]));
    },
  }),
};

export const APP_UPLOAD_FILE_TYPE = {
  PDF: "PDF",
  IMAGE: "IMAGE",
  WORD: "WORD",
  EXCEL: "EXCEL",
};

// docs: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
export const APP_UPLOAD_FILE_MIME_TYPES = {
  PDF: ["application/pdf", "pdf"],
  WORD: [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "docx",
  ],
  IMAGE: [
    "image/jpeg",
    "image/avif",
    "image/bmp",
    "image/gif",
    "image/vnd.microsoft.icon",
    "image/png",
    "image/svg+xml",
    "image/tiff",
    "image/webp",
    "jpeg",
    "jpg",
    "avif",
    "bmp",
    "gif",
    "vnd.microsoft.icon",
    "png",
    "svg+xml",
    "tiff",
    "webp",
  ],
  EXCEL: [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.oasis.opendocument.spreadsheet",
    "xlsx",
  ],
};

export const DEFAULT_COLUMN_SORT = "id";

export const APPROVAL_STATUS = {
  PROCESSING: "PROCESSING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
};

export const APPROVAL_STATUS_COLOR = {
  PROCESSING: "blue",
  APPROVED: "green",
  REJECTED: "red",
};

export const APPROVAL_STATUS_OPTIONS = [
  {
    value: APPROVAL_STATUS.APPROVED,
    label: (
      <IntlMessages
        id={`app.status.${APPROVAL_STATUS.APPROVED?.toLowerCase()}`}
      />
    ),
    text: (
      <IntlMessages
        id={`app.status.${APPROVAL_STATUS.APPROVED?.toLowerCase()}`}
      />
    ),
  },
  {
    value: APPROVAL_STATUS.PROCESSING,
    label: (
      <IntlMessages
        id={`app.status.${APPROVAL_STATUS.PROCESSING?.toLowerCase()}`}
      />
    ),
    text: (
      <IntlMessages
        id={`app.status.${APPROVAL_STATUS.PROCESSING?.toLowerCase()}`}
      />
    ),
  },
  {
    value: APPROVAL_STATUS.REJECTED,
    label: (
      <IntlMessages
        id={`app.status.${APPROVAL_STATUS.REJECTED?.toLowerCase()}`}
      />
    ),
    text: (
      <IntlMessages
        id={`app.status.${APPROVAL_STATUS.REJECTED?.toLowerCase()}`}
      />
    ),
  },
];

export const PRICE_CYCLE = {
  MONTHLY: "MONTHLY",
  YEARLY: "YEARLY",
};

export const PRICE_CYCLE_COLOR = {
  PRICE_CYCLE: "cyan",
  YEARLY: "orange",
};

export const PRICE_CYCLE_OPTIONS = Object.keys(PRICE_CYCLE).map((key) => ({
  value: key,
  label: <IntlMessages id={`app.priceCycle.${key?.toLowerCase()}`} />,
  text: <IntlMessages id={`app.priceCycle.${key?.toLowerCase()}`} />,
}));

export const OPEN_MODAL_PARAMS = {
  ORDER: "order-id",
  RESELLER: "reseller-id",
  LEAD: "lead-id",
};

export const COUNTRY_CODE_OPTIONS = [
  { value: "SG", label: "SG" },
  { value: "VN", label: "VN" },
];

export const LOCAL_STORAGE_KEY = {
  ACCESS_TOKEN: "cms.token",
  REFRESH_TOKEN: "cms.refreshToken",
};
