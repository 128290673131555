import React from "react";
import { ROUTER_PATH } from "shared/constants";

const LoginPage = React.lazy(() => import("./Login"));
const ForgotPassword = React.lazy(() => import("./ForgotPassword"));
const ResetPassword = React.lazy(() => import("./ResetPassword"));
const PartnerRegistration = React.lazy(() => import("./PartnerRegistration"));

export const authRouteConfig = [
  {
    path: ROUTER_PATH.LOGIN,
    element: <LoginPage />,
  },
  {
    path: "/forget-password",
    element: <ForgotPassword />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/partner-registration",
    element: <PartnerRegistration />,
  },
];
